<script setup lang="ts">
import {computed} from 'vue'
import {useGlobalStore} from '../../stores/global'
import {useCompanyStore} from '../../stores/company'
import SubMenu from './SubMenu.vue'

type Emit = {
  toggleMenu: []
}

interface Props {
  isBurgerMenu: boolean
  menuClasses: string | string[] | object
  menuItemClasses: string | string[] | object
  subMenuClasses: string | string[] | object
  subMenuItemClasses: string | string[] | object
}

const globalStore = useGlobalStore()
const companyStore = useCompanyStore()

const emits = defineEmits<Emit>()
defineProps<Props>()

interface SubMenuRoute {
  enabled: boolean
  key: string
  to: string
}

const adminRoutes = computed<SubMenuRoute[]>(() => {
  const routes: SubMenuRoute[] = []
  const isAdmin = globalStore.hasAuthority('ADMIN')
  routes.push({enabled: isAdmin, key: 'header.menu.administration.hotelAdministration', to: '/admin/hotel-administration'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.users', to: '/admin/users'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.roomStats', to: '/admin/room-stats'})
  routes.push({enabled: true, key: 'header.menu.administration.reservationOverview', to: '/admin/reservations'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.reportsOverview', to: '/admin/reports'})
  routes.push({enabled: true, key: 'header.menu.administration.outboundLogs', to: '/admin/outbound-logs'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.dataScience', to: '/admin/data-science'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.inboundData', to: '/admin/inbound-data'})
  routes.push({enabled: isAdmin, key: 'header.menu.administration.connectivityStats', to: '/admin/connectivity-stats'})
  routes.push({
    enabled: isAdmin,
    key: 'header.menu.administration.customerSuccessManagement',
    to: '/admin/customer-success-management',
  })
  return routes.filter((it) => !!it.enabled)
})

const analyseRoutes = computed<SubMenuRoute[]>(() => {
  const routes: SubMenuRoute[] = []
  const isMarketTrend = companyStore.marketTrends && companyStore.address?.countryCode === 'IT'
  routes.push({enabled: true, key: 'header.menu.analyse.analyse', to: '/analyse/analyse'})
  routes.push({enabled: true, key: 'header.menu.analyse.pickUp', to: '/analyse/pickup'})
  routes.push({enabled: true, key: 'header.menu.analyse.reputation', to: '/analyse/reputation'})
  routes.push({enabled: true, key: 'header.menu.analyse.budget', to: '/analyse/budget'})
  routes.push({enabled: isMarketTrend, key: 'header.menu.analyse.marketTrends', to: '/analyse/market-trend'})
  return routes.filter((it) => !!it.enabled)
})

const settingsRoutes = computed<SubMenuRoute[]>(() => {
  const routes: SubMenuRoute[] = []
  const isHotelAdmin = globalStore.hasAuthority('HOTEL_ADMIN')
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.notifications', to: '/settings/notifications'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.priceLevels', to: '/settings/price-levels'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.priceRules', to: '/settings/price-rules'})
  routes.push({enabled: true, key: 'header.menu.settings.interfaceConfiguration', to: '/settings/interfaces'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.competitors', to: '/settings/competitors'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.events', to: '/settings/events'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.roomPricingConfiguration', to: '/settings/room-pricing'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.budgetConfiguration', to: '/settings/budget'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.inventory', to: '/settings/inventory/config'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.ratePlans', to: '/settings/rate-plans'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.restrictions', to: '/settings/restrictions'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.statusConfiguration', to: '/settings/booking-status'})
  routes.push({enabled: isHotelAdmin, key: 'header.menu.settings.segmentation', to: '/settings/segmentation'})
  return routes.filter((it) => !!it.enabled)
})

function onRedirect() {
  emits('toggleMenu')
}
</script>

<template>
  <li v-if="globalStore.hasAuthority('VIEWER')" :class="menuClasses">
    <router-link :class="menuItemClasses" to="/" @click="onRedirect">{{ $t('header.menu.title.dashboard') }}</router-link>
  </li>

  <li v-if="globalStore.hasAuthority('VIEWER')" :class="menuClasses">
    <router-link :class="menuItemClasses" to="/manage" @click="onRedirect">{{ $t('header.menu.title.manage') }}</router-link>
  </li>

  <li v-if="globalStore.hasAuthority('VIEWER')" :class="menuClasses">
    <SubMenu :title="$t('header.menu.title.analyse')" :link-classes="menuItemClasses" :force-open="!isBurgerMenu">
      <ul :class="subMenuClasses">
        <template v-for="route in analyseRoutes" :key="route.key">
          <router-link :class="subMenuItemClasses" :to="route.to" @click="onRedirect">{{ $t(route.key) }}</router-link>
        </template>
      </ul>
    </SubMenu>
  </li>

  <li v-if="globalStore.hasAuthority('PMS_ADMIN', 'HOTEL_ADMIN')" :class="menuClasses">
    <SubMenu :title="$t('header.menu.title.settings')" :link-classes="menuItemClasses" :force-open="!isBurgerMenu">
      <ul :class="subMenuClasses">
        <template v-for="route in settingsRoutes" :key="route.key">
          <router-link :class="subMenuItemClasses" :to="route.to" @click="onRedirect">{{ $t(route.key) }}</router-link>
        </template>
      </ul>
    </SubMenu>
  </li>

  <li v-if="globalStore.hasAuthority('ADMIN', 'PMS_ADMIN')" :class="menuClasses">
    <SubMenu :title="$t('header.menu.title.administration')" :link-classes="menuItemClasses" :force-open="!isBurgerMenu">
      <ul :class="subMenuClasses">
        <template v-for="route in adminRoutes" :key="route.key">
          <router-link :class="subMenuItemClasses" :to="route.to" @click="onRedirect">{{ $t(route.key) }}</router-link>
        </template>
      </ul>
    </SubMenu>
  </li>

  <li v-if="globalStore.hasAuthority('VIEWER')" :class="menuClasses">
    <router-link :class="menuItemClasses" to="/activity-log" @click="onRedirect">
      {{ $t('header.menu.title.activityLog') }}
    </router-link>
  </li>
</template>
